@import "definitions";

.fibu_unassigned_items {
    h3 {
        margin-top: 0;
    }

    .tabs {
        margin-bottom: 0;
        position: relative;
    }

    .selected_amount_info {
        position: absolute;
        left: 22em;
        bottom: 1em;

        display: flex;

        > div {
            margin-right: 1em;
        }

        .amount {
            &_eq {
                font-weight: bold;
                color: green;
            }
            &_lt {
                font-weight: bold;
                color: orange;
            }
            &_gt {
                font-weight: bold;
                color:red;
            }
            &_selected {
                font-weight: bold;

                &.amount_lt {
                    color: black;
                }
            }
        }
    }

    table td, table th {
        padding: 2px;
        padding-left: $pad;
        
        &:first-child {
            padding-left: $pad;                
        }
    }

    .table_wrapper.matched_invoices table{
        td, th {
            height: 2.5em;
        }

        tr:nth-child(2n+2) {
            background-color: #efefef;
        }

        tr.highlight {
            //background-color: #ffdfdf;
            border: 1px solid #ff7676;
        }
    }

    .form_field_wrapper {
        display: flex;

        > div {
            width: 50%;
            
            textarea {
                width: 100%;
                max-height: 100%;
                height: 6em;
            }
        }
    }

    .has-border {
        margin-bottom: $mar;
        padding: $pad;
        border: $border;
    }

    .top_wrapper {
        display: flex;
        justify-content: space-between;    

        > div {
            width: 47%;
        }
    }

    .info_head {
        display: flex;
        margin-top: $mar;

        .info_amounts {
            width: 25%;
            padding-top: $pad;
            padding-left: $pad;
        }

        .info_close {
            width: 75%;
            display: flex;
            align-items: flex-end;

            > div {
                margin-right: $mar;                    
            }

            textarea {
                width: 100%;
                height: 5em;                    
            }
        }

        .amount {
            &_total {
                font-weight: bold;
                color: green;
            }
            &_matched {
                font-weight: bold;
            }
            &_open {
                font-weight: bold;
                
                &.amount_lt {
                    color: orange;
                }
                &.amount_eq {
                    color: green;
                }
                &.amount_gt {
                    color: red;
                }
            }
        }
    }

    .info_foot {
        margin-top: $mar;
    }
    

    .delete {
        @include sl-icon($fa-var-trash);
        color: $theme_color;
        cursor: pointer;
    }
}

.unassigned_info.overdue {
    color: darkorange;

    .minimal_button:not(:hover) .fa, .button_minimal:not(:hover) .fa{
        color: #444;
    }
}

.unassigned_info.active {
    color: royalblue;
}